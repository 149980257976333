import { registerApplication, start } from 'single-spa';
import { pathToRegexp } from 'path-to-regexp';

try {
	function hexToHsl(H) {
		if (H !== undefined) {
			// Convert hex to RGB first
			let r = 0,
				g = 0,
				b = 0;
			if (H.length == 4) {
				r = '0x' + H[1] + H[1];
				g = '0x' + H[2] + H[2];
				b = '0x' + H[3] + H[3];
			} else if (H.length == 7) {
				r = '0x' + H[1] + H[2];
				g = '0x' + H[3] + H[4];
				b = '0x' + H[5] + H[6];
			}
			// Then to HSL
			r /= 255;
			g /= 255;
			b /= 255;
			let cmin = Math.min(r, g, b),
				cmax = Math.max(r, g, b),
				delta = cmax - cmin,
				h = 0,
				s = 0,
				l = 0;

			if (delta == 0) h = 0;
			else if (cmax == r) h = ((g - b) / delta) % 6;
			else if (cmax == g) h = (b - r) / delta + 2;
			else h = (r - g) / delta + 4;

			h = Math.round(h * 60);

			if (h < 0) h += 360;

			l = (cmax + cmin) / 2;
			s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
			s = +(s * 100).toFixed(1);
			l = +(l * 100).toFixed(1);

			return '' + h + ' ' + s + '% ' + l + '%';
		} else {
			return '';
		}
	}

	(Element.prototype)._attachShadow = Element.prototype.attachShadow;
	(Element.prototype).attachShadow = function() {
		return this._attachShadow({ mode: 'open' });
	};

	let deviceTheme = 'theme_colors';

	const updateApiUrls = () => {
		const currentDomain = window.location.href;
		const urlApi = window.cconfig.urlApi.url;
		const urlCasinoPlay = window.cconfig.urlCasinoPlay;
		const urlApiFallback = window.cconfig.url_api; // Variable a priorizar

		if (urlApiFallback && currentDomain.includes('sp-')) {
			// Si url_api está definido, actualiza ambas variables
			let domainPrefix = currentDomain.split('.')[0];
			let domainPrefixCasino = currentDomain.split('.')[0];
			// Reemplazar 'sp-' con 'apidev-'
			domainPrefix = domainPrefix.replace('sp-', 'partnerapi-');
			domainPrefixCasino = domainPrefixCasino.replace('sp-', 'casino-');

			const initialUrl = new URL(urlApiFallback);
			const baseDomain = initialUrl.hostname.split('.').slice(1).join('.'); // Obtener dominio base
			const basePath = initialUrl.pathname;

			window.cconfig.url_api = `${domainPrefix}.${baseDomain}${basePath}`;
			window.cconfig.urlApi.url = window.cconfig.url_api; // Asegurar que urlApi.url se actualice también

			const initialUrlCasino = new URL(urlCasinoPlay);
			const baseDomainCasino = initialUrlCasino.hostname.split('.').slice(1).join('.'); // Obtener dominio base
			const basePathCasino = initialUrlCasino.pathname;

			window.cconfig.urlCasinoPlay = `${domainPrefixCasino}.${baseDomainCasino}${basePathCasino}`;
		} else if (currentDomain.includes('sp-')) {
			// Si url_api no está definido, usa urlApi.url
			let domainPrefix = currentDomain.split('.')[0];
			let domainPrefixCasino = currentDomain.split('.')[0];

			// Reemplazar 'sp-' con 'apidev-'
			domainPrefix = domainPrefix.replace('sp-', 'partnerapi-');
			domainPrefixCasino = domainPrefixCasino.replace('sp-', 'casino-');


			const initialUrl = new URL(urlApi);
			const baseDomain = initialUrl.hostname.split('.').slice(1).join('.'); // Obtener dominio base
			const basePath = initialUrl.pathname;

			window.cconfig.urlApi.url = `${domainPrefix}.${baseDomain}${basePath}`;
			window.cconfig.url_api = window.cconfig.urlApi.url; // Asegurar que url_api se actualice también

			const initialUrlCasino = new URL(urlCasinoPlay);
			const baseDomainCasino = initialUrlCasino.hostname.split('.').slice(1).join('.'); // Obtener dominio base
			const basePathCasino = initialUrlCasino.pathname;

			window.cconfig.urlCasinoPlay = `${domainPrefixCasino}.${baseDomainCasino}${basePathCasino}`;
		}
	};

	// setTimeout(()=>{
		updateApiUrls();
	// }, 10000)


	if (window.cconfig.theme_colors_desktop != undefined && window.cconfig.mobileL == '') deviceTheme = 'theme_colors_desktop';
	else if (window.cconfig.theme_colors_mobile != undefined && window.cconfig.mobileL != '') deviceTheme = 'theme_colors_mobile';


	if (window.cconfig[deviceTheme] !== undefined) {
		let rootTailwind = document.createElement('style');
		rootTailwind.textContent = `
    	        :root {
				--pf: ${hexToHsl(window.cconfig[deviceTheme].primary_focus)} !important;
				--sf: ${hexToHsl(window.cconfig[deviceTheme].secondary_focus)} !important;
				--af: ${hexToHsl(window.cconfig[deviceTheme].accent_focus)} !important;
				--su: ${hexToHsl(window.cconfig[deviceTheme].success)} !important;
				--in: ${hexToHsl(window.cconfig[deviceTheme].info)} !important;
				--wa: ${hexToHsl(window.cconfig[deviceTheme].warning)} !important;
				--er: ${hexToHsl(window.cconfig[deviceTheme].error)} !important;
				--p: ${hexToHsl(window.cconfig[deviceTheme].primary)} !important;
				--pc: ${hexToHsl(window.cconfig[deviceTheme].primary_content)} !important;
				--s: ${hexToHsl(window.cconfig[deviceTheme].secondary)} !important;
				--sc: ${hexToHsl(window.cconfig[deviceTheme].secondary_content)} !important;
				--a: ${hexToHsl(window.cconfig[deviceTheme].accent)} !important;
				--ac: ${hexToHsl(window.cconfig[deviceTheme].accent_content)} !important;
				--n: ${hexToHsl(window.cconfig[deviceTheme].neutral)} !important;
				--nf: ${hexToHsl(window.cconfig[deviceTheme].neutral_focus)} !important;
				--nc: ${hexToHsl(window.cconfig[deviceTheme].neutral_content)} !important;
				--b1: ${hexToHsl(window.cconfig[deviceTheme].base_100)} !important;
				--b2: ${hexToHsl(window.cconfig[deviceTheme].base_200)} !important;
				--b3: ${hexToHsl(window.cconfig[deviceTheme].base_300)} !important;
				--bc: ${hexToHsl(window.cconfig[deviceTheme].base_content)} !important;
    	}

      [data-theme="main"] .swiper-button-prev,
      [data-theme="main"] .swiper-button-next,
      [data-theme="main"] .swiper-button-prev::after,
      [data-theme="main"] .swiper-button-next::after {
        color: ${window.cconfig[deviceTheme].primary};
      }
`;


		try {
			document.body.appendChild(rootTailwind);
		} catch (e) {
			console.log(e);
		}

		let rootTailwind2 = document.createElement('style');
		if (window.cconfig.default_font !== undefined) {
			rootTailwind2.textContent = `
            * {
              font-family: '${window.cconfig.default_font}', sans-serif;
            }
        `;
		} else {
			rootTailwind2.textContent = `
            * {
              font-family: "Poppins", sans-serif;
            }
        `;
		}
		try {
			document.body.appendChild(rootTailwind2);
		} catch (e) {
			console.log(e);
		}

	}
} catch (e) {
	console.log(e);
}

let routesLanding = ['/'];
let routesSite = [
	'/home',
	'/registro',
	'/registro/:Codigo',
	'/registro/aff/:Codigo2',
	'/registro/a/:Codigo3',
	'/landing/registro',
	'/landing/casino',
	'/landing/registro-casino',
	'/landing/deportivas',
	'/landing/depositos',
	'/landing/registro-deportivas',
	'/landing/registro-corto',
	'/landing/registro-corto/:Codigo',
	'/landing/registro-corto/aff/:Codigo2',
	'/landing/registro-corto/a/:Codigo3',
	'/landing/registro-corto-loto',
	'/casino/bono',
	'/apuestas/bono',
	'/deportes',
	'/deportes/partido/:PartidoId',
	'/deportes/liga/:LigaId',
	'/deportes/pais/:PaisId',
	'/deportes/:DeporteId',
	'/deportes2',
	'/deportes2/partido/:PartidoId',
	'/deportes2/liga/:LigaId',
	'/deportes2/pais/:PaisId',
	'/deportes2/:DeporteId',
	'/apuestas',
	'/apuestas/partido/:PartidoId',
	'/apuestas/liga/:LigaId',
	'/apuestas/pais/:PaisId',
	'/apuestas/:DeporteId',
	'/lottery-bets',
	'/deportes-en-vivo',
	'/deportes-en-vivo2',
	'/apuestas-en-vivo',
	'/new-casino',
	'/new-casino/:GameId',
	'/new-casino/categoria/:CategoryId',
	'/new-casino/categoria/:CategoryId/:GameId',
	'/new-casino/proveedor/:ProviderId',
	'/live-casino-vivo',
	'/live-casino-vivo/:GameId',
	'/live-casino-vivo/categoria/:CategoryId',
	'/live-casino-vivo/categoria/:CategoryId/:GameId',
	'/live-casino-vivo/proveedor/:ProviderId',
	'/live-casino',
	'/live-casino/:GameId',
	'/pragmatic-vivo',
	'/pragmatic-vivo/:GameId',
	'/evolution',
	'/evolution/:GameId',
	'/betgamestv',
	'/betgamestv/:GameId',
	'/vivogaming',
	'/vivogaming/:GameId',
	'/poker',
	'/virtuales-lobby',
	'/virtuales-lobby/:GameId',
	'/virtuales-lobby/categoria/:CategoryId',
	'/virtuales-lobby/categoria/:CategoryId/:GameId',
	'/virtuales-lobby/proveedor/:ProviderId',
	'/virtual',
	'/virtualnew',
	'/virtual/:GameId',
	'/gestion/deposito',
	'/gestion/deposito-cuenta',
	'/gestion/tarjetas-credito',
	'/gestion/deposito/error',
	'/gestion/deposito/correcto',
	'/gestion/deposito/pendiente',
	'/gestion/cuenta_cobro',
	'/gestion/cuenta_cobro_anular',
	'/gestion/cuentasbancarias',
	'/consulta/consulta_tickets_online',
	'/consulta/consulta_tickets_online2',
	'/consulta/consulta_tickets_casino',
	'/consulta/consulta_depositos',
	'/consulta/consulta_retiros',
	'/gestion/gestion_cuenta',
	'/gestion/verificar_cuenta',
	'/gestion/cambiar-clave',
	'/gestion/misbonos',
	'/gestion/shop-bonuses',
	'/gestion/autoexclusion',
	'/gestion/autoexclusion-producto',
	'/gestion/limitedeposito',
	'/referido/agregar_referidos',
	'/referido/estado_referidos',
	'/shop-bonuses',
	'/shop-bonuses-cards/:categoryId',
	'/consulta/mi_lealtad',
	'/gestion/mi_lealtad',
	'/contactenos',
	'/trabaja-con-nosotros',
	'/terminosycondiciones',
	'/terminosycondiciones/:sectionId',
	'/politica-de-privacidad',
	'/politica-de-privacidad/:sectionId',
	'/preguntasfrecuentes',
	'/preguntasfrecuentes/:sectionId',
	'/juego-responsable',
	'/juego-responsable/:sectionId',
	'/geolocalizacion',
	'/reclamaciones',
	'/resultados',
	'/mundial',
	'/thankyou',
	'/graciasdeposito',
	'/promociones-bonos',
	'/promociones-bonos/:sectionId',
	'/promociones-bonos/id/:bonoId',
	'/banner-right',
	'/banner-left',
	'/banner-rightnl',
	'/banner-leftnl',
	'/banner-topcenter',
	'/banner-topcenternl',
	'/recuperar-clave',
	'/recuperar-clave-validar/:tokenP',
	'/recuperar-clave-activar/:tokenP',
	'/validar-actualizacion/:tokenP',
	'/verificar-email/:email/:tokenP',
	'/mensajes',
	'/torneos',
	'/bingos',
	'/sorteos',
	'/programa_lealtad',
	'/programa-lealtad',
	'/landing/app',
	'/landing/appIos',
	'/landing/cambiar-clave',
	'/landing/verificarCuenta',
	'/landing/sorteos/:LotteryId',
	'/liga/:ligaId',
	'/my-bonuses-progress',
	'/wallet',
	'/pos/login',
	'/pos/menu',
	'/pos/bets-sport',
	'/pos/change-password',
	'/pos/flow-cash',
	'/pos/flow-cash-summary',
	'/pos/prize-payment',
	'/pos/note-withdrawal-payment',
	'/pos/credit-recharge',
	'/pos/resultados',
];

const isLandingHomeDefined = window.cconfig.landingHome !== undefined && window.cconfig.landingHome;


if (!isLandingHomeDefined) {
	routesSite.unshift('/');
	// routesSite.unshift('/:pathMatch(.*)*');
} else {
	// routesLanding.unshift('/:pathMatch(.*)*');
	registerApplication({
		name: 'landing',
		app: () => import('@my-micro-apps/landing'),
		activeWhen: (location) => pathToRegexp(routesLanding).test(location.pathname),
	});
}

registerApplication({
	name: 'sitios',
	app: () => import('@my-micro-apps/SitioVersion5'),
	activeWhen: (location) => {
		const isRouteDefined = routesSite.some(route => pathToRegexp(route).test(location.pathname));
		if (!isRouteDefined && location.pathname !== '/') {
			routesSite.push('/:pathMatch(.*)*');
		}
		return pathToRegexp(routesSite).test(location.pathname);
	},
});

start();
